import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			// deviceType: 'pc',
			deviceType: 'mobile',
			showDebug: false,
			adsensConfig: {
				scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5103211735042916',
				ads: 'google.com, pub-5103211735042916, DIRECT, f08c47fec0942fa0',
				home_1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '1437846030', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				home_2: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '9124764368', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				home_3: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '7811682692', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				home_4: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '8119638609', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },

				all: [
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '2898331000', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '3549838207', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				],
			},
			adPosConfig: {
				all_m: [1, 2],
			},
			firebaseConfig: {
				apiKey: 'AIzaSyDPCzERUQwfWUSnOMxfKN_l1JI8B5jvPl0',
				authDomain: 'web-a2bf7.firebaseapp.com',
				projectId: 'web-a2bf7',
				storageBucket: 'web-a2bf7.appspot.com',
				messagingSenderId: '780267482533',
				appId: '1:780267482533:web:02367dc9cb858f11bf0c4c',
				measurementId: 'G-V09R8JPV8B',
			},
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setTitle(state, title) {
				state.title = title
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
		},
		getters: {
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsensConfig: (state) => state.adsensConfig,
			adPosConfig: (state) => state.adPosConfig,
		},
	})
}
